import * as React from 'react'
import Link from 'gatsby-link'
import { Button } from 'reactstrap'
import {getStateInstance} from './state'


export default function DebugStepLinks () {
    return null

    let pageState = getStateInstance()

    return <div>
        <Button onClick={() => pageState.clearForm()}>Clear form</Button>
        {" "}
        <Link to="/claim-form">Flight details</Link>
        {" "}
        <Link to="/claim-form/compensation-estimate">Submitter contact details</Link>
        {" "}
        <Link to="/claim-form/booking-details">Submitter itinerary</Link>
        {" "}
        <Link to="/claim-form/passengers">Passenger details</Link>
        {" "}
        <Link to="/claim-form/claim-registered">Claim submitted</Link>
    </div>
}
