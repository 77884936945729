import * as React from 'react'
import {Container} from 'reactstrap'
import {observer} from 'mobx-react'
import {Helmet} from 'react-helmet'
import Layout from '../../components/Layout/Layout'
import {FlightDetailsForm} from '../../components/ClaimForm/FlightDetailsForm'

import './claim-form.scss'
import DebugStepLinks from '../../components/ClaimForm/DebugStepLinks'


interface Props {
    location: Location
}


@observer
export default class ClaimFormFlightPage extends React.Component<Props> {

    render () {
        return <Layout className="claim-form-page">
            <Helmet>
                <meta name="robots" content="noindex"/>
            </Helmet>

            <Container>
                <div className="row mb-5">

                    <div className="offset-md-2 col-md-8">
                        <FlightDetailsForm location={this.props.location}/>
                    </div>
                </div>
            </Container>

            <DebugStepLinks/>

        </Layout>
    }
}
